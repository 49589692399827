<template>
  <div
    v-if="
        getUser.role.slug === 'super-administrateur' ||
        getUser.role.slug === 'rh' ||
        getUser.role.slug === 'assistant-rh'
    "
  >
    <b-tabs v-model="tabIndex" pills>
      
      <b-tab>
        <template #title>
            <feather-icon icon="UsersIcon" class="text-white mr-1" size="20" /> Propositions récurentes
          </template>
          <hr>

        <div
        v-if="isPageLoading"
        class="d-flex justify-content-center mb-1"
        style="padding-top: 30vh"
      >
        <b-spinner variant="info" style="width: 3rem; height: 3rem" class="mr-1" />
        </div>
        <div v-else >
            <b-card no-body>
          <b-card-header>
            <h5>Filtrages</h5>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="12" md="12" lg="12" class="mb-md-0 mb-2">
                <b-row>
                  <b-col cols="12" sm="6" md="3" lg="3">
                    <label for="">Client</label>
                    <v-select
                      v-model="payloadFilter.user_id"
                      :options="customerOptions"
                      class="invoice-filter-select"
                      label="name"
                      :reduce="(customer) => customer.id"
                    >
                      <template v-slot:no-options>
                        <b-spinner
                          v-if="isCustomersWithoutPaginationLoading"
                          style="width: 2.5rem; height: 2.5rem"
                          class="align-middle text-info"
                        />
                        <span v-else class="mt-1 font-medium-1"
                          >Aucun client ne correspond</span
                        >
                      </template>
                    </v-select>
                  </b-col>
                  
                
                  <b-col
                    v-if="
                      getUser.role.slug === 'super-administrateur' ||
                      getUser.role.slug === 'rh'
                    "
                    cols="12"
                    sm="6"
                    md="3"
                    lg="3"
                  >
                    <label for="">Ressource humaine</label>
                    <b-form-select
                      v-if="
                        AssistantRHOptions.length === 1 &&
                        AssistantRHOptions[0].disabled
                      "
                      id="arh_id"
                      :options="AssistantRHOptions"
                    />
                    <v-select
                      v-else
                      id="role"
                      v-model="payloadFilter.arh_id"
                      :reduce="(businessManager) => businessManager.id"
                      label="full_name"
                      :options="AssistantRHOptions"
                    >
                      <span slot="no-options" @click="$refs.select.open = false">
                        Chargement...
                      </span>
                    </v-select>
                  </b-col>
                  <b-col cols="12" sm="6" md="3" lg="3">
                        <label for="">Status</label>
                        <v-select v-model="payloadFilter.is_closed" :options="statusOptions" class="invoice-filter-select"
                          :reduce="(item) => item.value">
                          <template #selected-option="{ label }">
                            <span class="text-truncate overflow-hidden">
                              {{ label }}
                            </span>
                          </template>
                        </v-select>
                  </b-col>
                  
                  <b-col cols="12" sm="6" md="2" lg="2" class="mt-2">
                    <b-button variant="primary" @click="applyFilterPackagesAction()">
                      <div v-if="isReLoadFilterDataRecurringOrders">
                        <span> Chargement ... </span>
                        <b-spinner small />
                      </div>

                      <span v-else class="text-nowrap font-medium-1">Filtrer</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <b-row class="mb-4">
          <b-col cols="12" sm="6" md="3" lg="3">
                        <label for="">Status des commandes</label>
                        <v-select v-model="filtreStatus" :options="statusOrderOptions" class="invoice-filter-select"
                          :reduce="(item) => item.value">
                          <template #selected-option="{ label }">
                            <span class="text-truncate overflow-hidden">
                              {{ label }}
                            </span>
                          </template>
                        </v-select>
                  </b-col>

                  <b-col cols="12" sm="6" md="3" lg="3" v-if="filtreStatus == 'EM'">
                    <label for="">Employés</label>
                        <v-select v-model="selectEmployee" :options="employeeOptions"
                          class="invoice-filter-select" label="name" :reduce="(employee) => employee.id">
                          <template v-slot:no-options>
                            <b-spinner v-if="isEmployeesWithoutPaginationLoading" style="width: 2.5rem; height: 2.5rem"
                              class="align-middle text-info" />
                            <span v-else class="mt-1 font-medium-1">Aucun employée ne correspond</span>
                          </template>
                        </v-select>
                  </b-col>

        </b-row>
          <b-row>
            <!-- Per Page -->
            <b-col   v-if="
                  getUser.role.slug === 'rh' ||
                  getUser.role.slug === 'super-administrateur' 

                  ">
                  <b-button 
                  v-if=" getUser.role.slug === 'rh'"
                  variant="primary" 
                  v-b-modal.modal-rapport-suivi 
                  @click="applyGetUnpublishedSuivis()" 
                  >
                    <div v-if="isRapportSuivi">
                      <span> Chargement ... </span>
                      <b-spinner small />
                    </div>

                    <span v-else class="text-nowrap font-medium-1"><feather-icon
                        icon="NavigationIcon"
                        class="text-white mr-1"
                        size="20"
                      />Rapport de suivi</span>
                  </b-button>
              </b-col>

            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-end mb-2"
            >
            
              <b-button
                variant="primary"
                @click="reLoadDataRecurringOrdersAction()"
              >
                <div v-if="isReLoadDataRecurringOrders">
                  <span> Chargement ... </span>
                  <b-spinner small />
                </div>
                <span
                  v-else
                  class="text-nowrap font-medium-1"
                >Actualiser</span>
              </b-button>
              
            </b-col>
          </b-row>
          <b-row >

          </b-row>
          <div  class='flex items-center text-center justify-center' :is-loading="isDirectPackagesLoading">
            <div class="text-center text-info" v-if="isDirectPackagesLoading">
                <b-spinner class="align-middle" /> &nbsp;
                <span class="align-middle">Chargement... </span>
              </div>
              <!-- <div class="text-center">
                <span>{{ isDirectPackagesLoading ? "" : "Liste vide" }}</span>
              </div> -->    
        </div>
    <!--Debut  Package Card -->
      <div v-if=" isDirectPackagesLoading == false && clientPackage.length == 0" class="text-center ">

          <span class="text-center font-size-18 text-info">Liste vide</span>

        </div>
        <div  v-else>
      <b-row class="match-height"  >
        <b-col
            lg="3"
            md="4"
            v-for="userpackage in clientPackage" :key="userpackage.id"
            >    
        <b-card
        
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile mt-5  " 
          v-bind:class="{ 'red-card': userpackage.recommandations_count === 0 || userpackage.recommandations_count < userpackage.recurring_order_sum_number_of_employees }"
        >
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
             
        <b-avatar
          size="114"
          variant="dark"
          :text="
            avatarText(
              `${userpackage.user.first_name} ` + ` ${userpackage.user.last_name}`
            )
          "
          :src="userpackage.profile_image"
        />
      </div>
    </div>
    <h3>{{ userpackage.user.last_name }} {{ userpackage.user.first_name }}</h3>

          <h6 class="mt-1 text-info">Résumé des commandes</h6>
          <hr>


          <div v-if="userpackage.terminated_count != 0">
          <span><b-badge class="profile-badge" variant="light-danger">{{userpackage.terminated_count}} Résilié</b-badge></span>
          </div>
        <div v-if="userpackage.actifs_count != 0">
        <span class="text-justify"><b-badge class="profile-badge" variant="light-success">{{userpackage.actifs_count}} Actifs</b-badge></span>
        </div>

        <div v-if="userpackage.contracts_approved_count != 0">
        <span><b-badge class="profile-badge" variant="light-info">{{userpackage.contracts_approved_count}} Contrats approuvés</b-badge></span>
        </div>

        <div v-if="userpackage.contracts_issued_count != 0">
        <span><b-badge class="profile-badge" variant="light-dark">{{userpackage.contracts_issued_count}} Contrats  émis</b-badge></span>
        </div >

        <div v-if="userpackage.employee_proposed_count != 0">
        <span><b-badge class="profile-badge" variant="light-white">{{userpackage.employee_proposed_count }} Employés proposés</b-badge></span>
        </div>

        <div v-if="userpackage.wait_propositions_count != 0">
        <span><b-badge class="profile-badge" variant="light-info">{{userpackage.wait_propositions_count}} En attente propositions</b-badge></span>
        </div>

        <div v-if="userpackage.wait_submitted_count != 0">
    <span><b-badge class="profile-badge" variant="light-info">{{userpackage.wait_submitted_count}} En attente soumission</b-badge></span>
    </div>

        <div v-if="userpackage.wait_feedback_count != 0">
        <span><b-badge class="profile-badge" variant="light-warning">{{userpackage.wait_feedback_count}} En attente de feedback</b-badge></span>
        </div>

        <div v-if="userpackage.un_paid_count != 0">
        <span><b-badge class="profile-badge" variant="light-danger">{{userpackage.un_paid_count}} En attente de paiement</b-badge></span>
        
        </div>
        
        <!-- <h6 class="text-muted">
          <b-badge class="profile-badge" variant="light-success">.</b-badge>
          <b-badge class="profile-badge" variant="light-info">3</b-badge>
          <b-badge class="profile-badge" variant="light-warning">3</b-badge>
          <b-badge class="profile-badge" variant="light-danger">5</b-badge>
        </h6> -->
        <div >
        <span>Total: <span class="text-success">{{userpackage.recurring_orders_count}} commandes</span></span>
        </div>

        <hr>
        <div v-if="getUser.role.slug !== 'assistant-rh'">
          <h6 class=" text-sm">
            <h6 v-if="userpackage.rh !== null">
              RH: {{ userpackage.rh.full_name }}
            </h6>
          <h6  v-else>
            Non assignée</h6>    
          </h6>
        </div>

        <div >
            <div v-if="userpackage.assign_to !== null">
              <h6 class="text-sm" >
                Commercial: {{  userpackage.assign_to.full_name }}
              </h6>
            </div>
            <div v-else>
              <h6 class="text-sm" >
              Non assigné
              </h6>
            </div>
        </div>
          <b-button
          
              variant="primary"
              @click="currentPackageClient(userpackage)"
              class="mt-1"  
          >
          Ouvrir    
          </b-button>
        </b-card>
        </b-col>
        </b-row>
        </div>      

      <!--Fin Package Card -->
          <div class="d-flex justify-content-between mt-2 flex-wrap">
            <b-button
              :disabled="metaData.prev_page_url == null"
              variant="outline-secondary"
              @click="applyLoadMoreDirectPackagesAction(metaData.prev_page_url)"
            >
              <span class="text-white text-nowrap font-medium-2">Page précédente</span>
            </b-button>

            <div>
              <span class="text-white font-medium-2">{{ metaData.current_page }} sur {{ metaData.last_page }} pages</span>
            </div>
            <div>
              <span
                class="text-white font-medium-2"
              >Total :{{ total }}</span>
            </div>

            <b-button
              :disabled="metaData.next_page_url == null"
              variant="outline-secondary"
              @click="applyLoadMoreDirectPackagesAction(metaData.next_page_url)"
            >
              <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
            </b-button>
          </div>

       
        <b-modal
          id="modal-action-times"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
        >
          <b-row class="">
            <b-col
              md="6"
              cols="12"
            >
              <p class="card-text mb-25 font-medium-1">
                Date de la commande:
                <span class="text-warning">
                  {{ moment(currentOrder.created_at).locale("fr").format("llll") }}
                </span>
              </p>
              <p class="card-text mb-25 font-medium-1">
                Date d'affectation:
                <span class="text-warning">
                  {{
                    !currentOrder.assign_to
                      ? "Non effectué"
                      : !currentOrder.auto_assignment
                        ? moment(currentOrder.assign_at).locale("fr").format("llll")
                        : "Auto assignation"
                  }}
                </span>
              </p>
              <p class="card-text mb-25 font-medium-1">
                Date du feedback:
                <span class="text-warning">{{
                  currentOrder.feedback_make_at
                    ? moment(currentOrder.feedback_make_at).locale("fr").format("llll")
                    : "Non effectué"
                }}</span>
              </p>
              <p class="card-text mb-25 font-medium-1">
                Dernière recommandation:
                <span class="text-warning">
                  {{
                    currentOrder.recommandation_make_at
                      ? moment(currentOrder.recommandation_make_at)
                        .locale("fr")
                        .format("llll")
                      : "Non effectué"
                  }}
                </span>
              </p>
            </b-col>
            <b-col
              md="6"
              cols="12"
            >
              <p class="card-text mb-25 font-medium-1">
                Dernière proposition:
                <span class="text-warning">
                  {{
                    currentOrder.proposition_make_at
                      ? moment(currentOrder.proposition_make_at).locale("fr").format("llll")
                      : "Non effectué"
                  }}
                </span>
              </p>
              <p class="card-text mb-25 font-medium-1">
                Date de déploiement:
                <span class="text-warning">{{
                  currentOrder.employee_contract_started_date
                    ? moment(currentOrder.employee_contract_started_date).locale("fr").format("llll")
                    : 'Non effectué'
                }}</span>
              </p>
            </b-col>
          </b-row>
        </b-modal>
        <!-- Deploy Employee Modal -->
        <b-modal
          id="modal-update-deploiement-date"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          title="Date de déploiement d'employé"
        >
          <validation-observer
            #default="{}"
            ref="deployEmployeeForm"
          >
            <!-- Form -->
            <b-form
              class="p-2"
              autocomplete="off"
              @reset.prevent="hideModalUpdateDeployEmployeeDate()"
              @submit.prevent="applyUpdateDeploymentEmployee()"
            >
              <b-form-group
                label="Date de déploiement"
                label-for="role"
              >
                <validation-provider
                  #default="{ errors }"
                  name="date de déploiement"
                  rules="required"
                >
                  <flat-pickr
                    v-model="employee_contract_started_date"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  :disabled="isUpdateDeployementDateLoading"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  <div v-if="isUpdateDeployementDateLoading">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span v-else>Modifier la date</span>
                </b-button>
                <b-button
                  type="reset"
                  variant="outline-secondary"
                >
                  Annuler
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-modal>
        <!-- Rapport de suivi Employé Modal -->
            <b-modal
              id="modal-rapport-suivi"
              cancel-variant="outline-secondary"
              hide-footer
              centered
              no-close-on-backdrop
              no-close-on-esc
              :title="`Rapport du suivi des employés`"
              size="xl"
            >
            <div v-if="isRapportSuiviLoading">
                        <span> Chargement ... </span>
                        <b-spinner small />
                      </div>
              <div class="justify-content-center" v-else>
                <b-row>
                  <b-col md="12">
                    <b-row>
                      <b-col>
                        <span class="text-white"> Suivi Employé injoignable : </span>
                        <span class="text-info"> {{ payloadSuivisUnpublished.unreachable}} </span>
                      </b-col>
                      <b-col>
                        <span>
                          <strong class="text-info"></strong>
                        </span>
                      </b-col>
                    </b-row>
                    <br />
                    <b-row>
                      <b-col>
                        <span class="text-white"> Suivi Rien à signaler : </span>
                        <span class="text-info"> {{ payloadSuivisUnpublished.ras}} </span>
                      </b-col>
                      <b-col>
                        <span>
                          <strong class="text-info">
                          </strong
                          >
                        </span>
                      </b-col>
                    </b-row>
                    <br />
                    <b-row>
                      <b-col>
                        <span class="text-white">Autres Situation particuliere : </span>
                        <span class="text-info">Voir le tableau ci-dessous</span>
                      </b-col>
                      <b-col>
                        <span>
                          <strong class="text-info"></strong>
                        </span>
                      </b-col>
                    </b-row>
                    <br />
                    <hr />
                    <b-row>
                        <!-- List des commentaires -->
              <b-table
                ref="refInvoiceListTable"
                :items="rapports"
                responsive
                :fields="columnsRapport"
                :busy="isRapportSuivi"
                primary-key="id"
                show-empty
                empty-text="Aucune autre situation particulière"
                class="position-relative"
              >
                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle" /> &nbsp;
                    <strong>Chargement...</strong>
                  </div>
                </template>
                <!-- Commentaire -->
                <template #cell(employee)="data">
                  <span> {{ data.item.employee == null ? 'Non renseigné' : data.item.employee.full_name }} </span>
                </template>
                <!-- Make by -->
                <template #cell(suivis_make_by)="data">
                  <span>
                    {{ data.item.suivis_make_by.last_name}}
                  </span>
                  <span>
                    {{ data.item.suivis_make_by.first_name}}
                  </span>
                </template>
                <!-- Make at -->
                <template #cell(suivis_date)="data">
                  <span>
                    {{ moment(data.item.suivis_date).locale("fr").format("llll")}}
                  </span>
                </template>
              </b-table>
                    
                    </b-row>
                    <br />
                  </b-col>
                </b-row>
                <br />
              </div>
              <hr />
                
              <div class="d-flex mt-2">
                    <b-button
                      :disabled="isSendSuiviRapport"
                      variant="primary"
                      class="mr-2"
                      @click="envoiRapport()"
                    >
                      <div v-if="isSendSuiviRapport">
                        <span> Chargement ... </span>
                        <b-spinner small />
                      </div>
                      <span v-else>Envoyez Rapport</span>
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                      @click="hideModalRapport()"
                    >
                      Annuler
                    </b-button>
                  </div>
            </b-modal>
        </div>
      </b-tab>

      <b-tab>
      <template #title>
        <feather-icon icon="TagIcon" class="text-white mr-1" size="20" />Propositions business
      </template>
      <hr>
      <business-recommandations />
     
    </b-tab>
    </b-tabs>
  </div>

  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BBadge,
  BPagination,
  BTooltip,
  BSpinner,
  BFormSelect,
  BForm,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import NotAuthorized from '@/layouts/components/NotAuthorized.vue'
import PackageCard from '@/layouts/components/PackageCard'
import businessRecommandations from '@/views/work-providers/business/PendingBusinessOrders.vue'

export default {
  name: 'OrderIndex',
  components: {
    PackageCard,
    BTab,
    BTabs,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BPagination,
    BTooltip,
    BFormSelect,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    flatPickr,
    vSelect,
    BDropdown,
    BDropdownItem,
    NotAuthorized,
    businessRecommandations
  },
 
  data() {
    return {
      ispageLoading: true,
      isRapportSuivi: false,
       isComments: false,
      rapports: [],
      employeeOptions: [],
      payloadFiltre: {
        filter:"",
      },
       columnsRapport: [
        {
          key: "employee",
          label: "Employés",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "resum",
          label: "Resumé du suivi",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "suivis_make_by",
          label: "Suivi effectué par",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "suivis_date",
          label: "Date ",
          sortable: true,
          class: "font-medium-1",
        },
        
      ],
      statusOrderOptions: [
        {
          label: "En att. proposition",
          value: "AP",
        },
        {
          label: "Contrat approuvé",
          value: 3,
        },
        {
          label: "Filtre employe",
          value: "EM",
        },
        
      ],
      avatarText,
      AssistantRHOptions: [],
      isRapportSuiviLoading: false,
      userpackage : {},
      isLoading: true,
      isRapportSuivi: false,
       isSendSuiviRapport: false,
      payloadSuivisUnpublished :{
        suivi_type: "",
        unreachable: "",
        ras:"",
        others: [],
      },
      clientPackage: [],
      total: '',
      isUpdateDeployementDateLoading: false,
      tableItems: [],
      employee_contract_started_date: '',
        isComments: false,
      comments: [],
       columnsComments: [
        {
          key: "comment",
          label: "Employés",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "resum",
          label: "Resumé du suivi",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "comment_make_by",
          label: "Suivi effectué par",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "comment_at",
          label: "Date ",
          sortable: true,
          class: "font-medium-1",
        },
        
        
        { key: "actions", class: "font-medium-1" },
      ],
  
      statusOptions: [
        {
          label: "Contrat terminé",
          value: 1,
        },
        {
          label: "Contrat non terminé",
          value: 0,
        },
      ],
      filtreStatus : "",
      managerOptions: [],

      customerOptions: [],

      payloadFilter: {
        user_id: '',
        arh_id: '',
        AssistantRHOptions: '',
        employee_id: '',
        is_closed: "",
      },

      businessManagerOptions: [],

      isOrderAssignmentLoading: false,

      payload: {
        charge_daffaire_id: '',
      },

      orderToAssign: {},
      currentOrder: {},
      required,
      rows1: [],
      rows: [],
      columns: [
        {
          label: 'Client',
          field: 'user',
          filterOptions: {
            enabled: false,
            placeholder: 'Rechercher par Client',
            filterFn(data, filterString) {
              return data.full_name.toLowerCase().includes(filterString.toLowerCase())
            },
          },
        },
        {
          label: 'Service demandé',
          field: 'recurring_service',
          filterOptions: {
            enabled: false,
            placeholder: 'Rechercher par Service',
            filterFn(data, filterString) {
              return data.name.toLowerCase().includes(filterString.toLowerCase())
            },
          },
        },
        {
          label: 'Status',
          field: 'setStatus',
          filterOptions: {
            enabled: false,
            placeholder: 'Rechercher par Status',
            filterDropdownItems: [
              'En attente de feedback',
              'En attente de recommandation',
              'En attente de proposition',
              'Résilié',
              'Employé proposé',
              'Non Payé',
              'Contract émis',
              'Contract approuvé',
              'Actif',
              'Terminée',
            ],
            filterFn(data, filterString) {
              return data.toLowerCase() === filterString.toLowerCase()
            },
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      selectEmployee: "",
      dir: false,
      isReLoadDataRecurringOrders: false,
      isReLoadFilterDataRecurringOrders: false,
    }
  },
  computed: {
   
    ...mapGetters('packages', ['getDirectPackages']),
    ...mapGetters('orders', ['getDirectOrders']),
    ...mapGetters([
      'isDirectOrdersLoading',
      'isDirectPackagesLoading',
      'isDirectOrdersFilterLoading',
      'isCustomersWithoutPaginationLoading',
      'isEmployeesWithoutPaginationLoading'
    ]),
    ...mapGetters('professionals', [
      'getEmployees',
      'getEmployeesWithoutPagination',
    ]),
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('roles', ['getBusinessManagersList']),
    ...mapGetters('customers', ['getCustomersWithoutPagination']),
    

    

    metaData() {
      const meta = {
        prev_page_url: "",
        next_page_url: "",
        current_page: "",
        last_page: "",
        current_page_url: "",
      };
      if (this.getDirectPackages != null) {
        meta.prev_page_url = this.getDirectPackages.prev_page_url;
        meta.next_page_url = this.getDirectPackages.next_page_url;
        meta.current_page = this.getDirectPackages.current_page;
        meta.last_page = this.getDirectPackages.last_page;
        meta.current_page_url = `${this.getDirectPackages.path}?page=${this.getDirectPackages.current_page}`;
        this.total = this.getDirectPackages.total;
      }
      return meta;
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
  },
  watch: {
    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue)
        keys.forEach(key => {
          if (newValue[key] == null) {
             this.applyGetDirectPackagesAction()
            newValue[key] = ''
            console.log(this.payloadFilter)
          }
        })
      },
      deep: true,
    },
     isDirectPackagesLoading(val) {
      if (val === false) {
        this.clientPackage = this.getDirectPackages.data;
      }
    },
    isDirectOrdersLoading(val) {
      if (val === false) {
        this.rows = this.customTab(this.getDirectOrders.data)
      }
    },
    getDirectOrders(newValue, oldValue) {
      if (oldValue.data.length != 0 && oldValue.data.length != newValue.data.length) {
        this.rows = this.customTab(this.getDirectOrders.data)
      }
    },
    isEmployeesWithoutPaginationLoading(val) {
      if (val === false) {
        this.getEmployeesWithoutPagination.forEach(element => {
          this.employeeOptions.push({
            id: element.id,
            name: element.full_name,
          })
        })
      }
    },
    isCustomersWithoutPaginationLoading(val) {
      if (val === false) {
        this.getCustomersWithoutPagination.forEach(element => {
          this.customerOptions.push({
            id: element.id,
            name: element.customer_full_name,
          })
        })
      }
    },

    filtreStatus(val){
      if(val != null && val != "EM"){
        this.applyFilterPackagesCommandesAction(val)
      }
      else if(val == "EM") {
       val = "EM"
      }
      else
      {
        this.reLoadDataRecurringOrdersAction()
      }
      
    },

    selectEmployee(val){
      if(val != null){
        this.applyFilterPackagesCommandeAction(val)
      }
      else 
      {
        this.reLoadDataRecurringOrdersAction()
      }
    }
    
  },
  created() {
    
    // this.getDirectPackages != null
    //    (this.clientPackage = this.getDirectPackages)
    //   : null
    //   console.log('CLIENT',this.clientPackage)
    // this.package()
    // applyGetDirectPackagesAction() 
    // this.applyGetBusinessManagersListAction()
    // this.getDirectOrders.data.length != 0
    //   (this.rows = this.customTab(this.getDirectOrders.data))
    //   : null

    // if (this.isDirectOrdersLoading === false) {
    //   this.rows = this.customTab(this.getDirectOrders.data)
    // }

     console.log('PPP',this.getDirectPackages)
    this.getDirectPackages.length != 0 ? (this.clientPackage = this.getDirectPackages.data) : this.applyGetDirectPackagesAction();
    this.applyGetDirectPackagesAction()
   
    this.applygetRHListAction()
    // this.getDirectOrders.data.length != 0
    //   (this.rows = this.customTab(this.getDirectOrders.data))
    //   : null
  },
  mounted() {
    // console.log('mapgetter', this.getDirectPackages)
    if (
      this.getUser.role.slug === 'super-administrateur'
      || this.getUser.role.slug === 'responsable-relation-client'
    ) {
      this.columns.splice(2, 0, {
        label: 'Traitée par',
        field: 'assign_to',
      })
    }

    if (this.getCustomersWithoutPagination.length != 0) {
      this.getCustomersWithoutPagination.forEach(element => {
        this.customerOptions.push({
          id: element.id,
          name: element.customer_full_name,
        })
      })
    }

    if (this.getEmployeesWithoutPagination.length != 0) {
      this.getEmployeesWithoutPagination.forEach(element => {
        this.employeeOptions.push({
          id: element.id,
          name: element.full_name,
        })
      })
    }
  },
  methods: {

    ...mapActions('roles', ['getBusinessManagersListAction', 'getAssistantRHListAction', "getRHListAction"]),
    ...mapActions('orders', [
      'assignAnOrderToBusinessManagerAction', 'changeEmployeeDeploymentAction',
      'getDirectOrdersAction',
      'getDirectOrdersAssignedToBusinessManagerAction',
      'loadMoreDirectOrderAction',
      'filterDirectOrderAction'
    ]),
    ...mapActions('packages', ['getDirectPackagesAction',  'filterPackagesAction', 'loadMoreDirectPackagesAction', 'filterPackagesCommandesAction']),
    ...mapActions('customers', ['getCustomersWithoutPaginationAction']),
    ...mapActions('professionnal', ['getEmployeeListAction']),
    ...mapActions("suivis", ["getUnpublishedSuivisAction", "sendRapportSuivisAction"]),

     // RAPPORT DE SUIVIS CLIENT
    applyGetUnpublishedSuivis(){
      this.isRapportSuivi = true
       this.payloadSuivisUnpublished.suivi_type = "employee"
      this.getUnpublishedSuivisAction(this.payloadSuivisUnpublished)
      .then(response => {
        this.isRapportSuivi = false
         this.payloadSuivisUnpublished = response.data
        this.rapports = response.data.others
         console.log(response)
      })
      .catch(error => {
        this.isRapportSuivi = false
         this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
      })
    },
    
    hideModalRapport(){
      this.$bvModal.hide("modal-rapport-suivi");
    },
    envoiRapport(){
      this.isSendSuiviRapport = true
      this.sendRapportSuivisAction()
      .then(response => {
        this.isSendSuiviRapport = false
       this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            this.hideModalRapport()
          
      })
      .catch(error => {
        this.isSendSuiviRapport = false
        this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
      })
    },

     applygetAssistantRHListAction() {
      this.getAssistantRHListAction().then((response) => {
        if (response.data.length === 0) {
          this.AssistantRHOptions.push({
            value: "",
            disabled: true,
            text: "La liste des assistants RH est vide",
          });
        } else {
          response.data.forEach((element) => {
            this.AssistantRHOptions.push(element);
          });
        }
      });
    },

    applygetRHListAction() {
      this.getRHListAction().then((response) => {
        if (response.data.length === 0) {
          this.AssistantRHOptions.push({
            value: "",
            disabled: true,
            text: "La liste des assistants RH est vide",
          });
        } else {
          response.data.forEach((element) => {
            this.AssistantRHOptions.push(element);
          });
        }
      });
    },

    applygetEmployeeListAction() {
      this.getEmployeeListAction().then((response) => {
        if (response.data.length === 0) {
          this.employeeOptions.push({
            value: "",
            disabled: true,
            text: "La liste des employés est vide",
          });
        } else {
          response.data.forEach((element) => {
            this.employeeOptions.push(element);
          });
        }
      });
    },
//package liste action
  applyGetDirectPackagesAction() {
        this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', true)
        this.getDirectPackagesAction()
          .then(response => {
            this.ispageLoading = false
            this.clientPackage = response.data.data
            this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', false)
          })
          .catch(() => {
            this.ispageLoading = false
            this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', false)
          })
      },
      newCommand(){
        this.$router.push('/orders/direct/new/command?search_employee=true&client_hide=false')
        this.$store.commit('SET_IS_PACKAGE_CLICKED', false)
    },
       newCommandGestion(){
         this.$router.push('/orders/direct/new/commandGestion?search_employee=false&client_hide=false')
         this.$store.commit('SET_IS_PACKAGE_CLICKED', false)
    },

   currentPackageClient(Userpackage){
    this.$store.commit("packages/SET_CURRENT_PACKAGE", Userpackage);
      localStorage.setItem("clientPackage", JSON.stringify(Userpackage));
    this.$router.push(`/packages/${Userpackage.id}/work/provider/recomandation`)
  },

    // package(){
    //   this.isLoading = true
    //   this.getDirectPackagesAction()
    //   .then(response =>{
    //     this.isLoading = false
    //     this.clientPackage = response.data
    //     this.toast.success(response.message)
    //   })
    //   .catch(error =>{
    //     this.toast.success(error)
    //   })
    // },


    applyUpdateDeploymentEmployee() {
      this.$bvModal.hide('modal-update-deploiement-date')
      this.isUpdateDeployementDateLoading = true
      this.$swal
        .fire({
          title: "Modifier la date de déploiement d'employé",
          text: 'Êtes-vous sûr de vouloir modifier la date de déploiement  cet employé ?',
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: 'Non',
          allowOutsideClick: false,
          confirmButtonText: 'Oui',
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
          preConfirm: () => this.changeEmployeeDeploymentAction({
            orderId: this.currentOrder.id,
            payload: { employee_deployment_date: this.employee_contract_started_date },
          })
            .then(response => {
              this.isUpdateDeployementDateLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            })
            .catch(error => {
              this.$swal.showValidationMessage(
                `${error.response.data.message}`,
              )
            }),
        })
        .then(response => {
          if (response.isConfirmed) {
            this.hideModalUpdateDeployEmployeeDate()
            this.getDirectOrdersAction()
          } else {
            this.hideModalUpdateDeployEmployeeDate()
          }
        })
        .catch(error => {
          this.isUpdateDeployementDateLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    hideModalUpdateDeployEmployeeDate() {
      this.employee_contract_started_date = '',
      this.isUpdateDeployementDateLoading = false
      this.$bvModal.hide('modal-update-deploiement-date')
    },
    putRecurringOrdersInStoreAction(directOrder) {
      this.$store.commit('orders/SET_DIRECT_ORDER', directOrder)
      this.$router.push({
        name: 'admin-orders-recurring-details',
        params: { id: directOrder.id },
      })
    },

    reLoadDataRecurringOrdersAction() {
      this.payloadFilter.user_id = ''
      this.payloadFilter.arh_id = '',
      this.payloadFilter.is_closed = '',
      this.filtreStatus= '',
      this.selectEmployee = '',

      this.isReLoadDataRecurringOrders = true
      this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', true)
      this.rows = []

      this.getDirectPackagesAction()
        .then(response => {
          this.isReLoadDataRecurringOrders = false
          this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', false)
        })
        .catch(error => {
          this.isReLoadDataRecurringOrders = false
          this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', false)
        })
    },

    applyLoadMoreDirectPackagesAction(url) {
      this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", true);
      this.clientPackage = [];
      this.loadMoreDirectPackagesAction(url)
        .then(() => {
          this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", false);
        })
        .catch(() => {
          this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", false);
        });
    },
    showUpdateDeploiementDateModal(item) {
      console.log(item)
      this.$bvModal.show('modal-update-deploiement-date')
      this.employee_contract_started_date = item.employee_contract_started_date
      this.currentOrder = item
    },
    showActionTimesModal(item) {
      this.$bvModal.show('modal-action-times')
      this.currentOrder = item
    },

    applyGetDirectOrdersAssignedToBusinessManagerAction(id) {
      this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', true)
      this.getDirectOrdersAssignedToBusinessManagerAction(id)
        .then(() => {
          this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', false)
        })
    },
    applyGetDirectOrderAction() {
      this.$store.commit('SET_IS_DIRECT_ORDERS_LOADING', true)
      this.getDirectOrdersAction()
        .then(() => {
          this.$store.commit('SET_IS_DIRECT_ORDERS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_DIRECT_ORDERS_LOADING', false)
        })
    },

    applyGetBusinessManagersListAction() {
      this.getBusinessManagersListAction().then(response => {
        if (response.data.length === 0) {
          this.businessManagerOptions.push({
            value: '',
            disabled: true,
            text: "La liste des chargés d'affaire est vide",
          })
        } else {
          response.data.forEach(element => {
            this.businessManagerOptions.push(element)
          })
        }
      })
    },

    applyOrderAssignment() {
      this.isOrderAssignmentLoading = true
      this.assignAnBusinessOrderToARHAction({
        orderId: this.item,
        payload: this.payloadAssignment,
      })
        .then(async response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Succès',
                icon: 'CheckIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            },
          )
          this.isOrderAssignmentLoading = false
          this.reLoadDataRecurringOrdersAction()
          this.hideModalAssignment()
          
        })
        .catch(error => {
          this.isOrderAssignmentLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyOrderAssignment() {
      this.isOrderAssignmentLoading = true
      this.assignAnOrderToBusinessManagerAction({
        orderId: this.orderToAssign.id,
        payload: this.payload,
      })
        .then(async response => {
          this.applyLoadMoreDirectPackagesAction(this.metaData.current_page_url)
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Succès',
                icon: 'CheckIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            },
          )
          this.isOrderAssignmentLoading = false
          this.$bvModal.hide('modal-order-assignment')
          this.payload = {
            charge_daffaire_id: '',
          }
        })
        .catch(error => {
          this.isOrderAssignmentLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyFilterPackagesCommandeAction(val) {
      const allIsNUl = Object.values(val).every((el) => el === "");
        const payloadFiltreStatusEmployee = {
        filter: "EM",
        employee_id : this.selectEmployee
        }              
      console.log(val)

      if(val != ""){

        this.isReLoadDataRecurringOrders = true
        console.log(val)
          this.filterPackagesCommandesAction(payloadFiltreStatusEmployee)
          .then((response) => {
            console.log(response)
           
            if(response.data.length !== 0){
               this.clientPackage = response.data
               this.total = this.clientPackage.length
            }
            else{
                this.clientPackage = []
            }
            this.isReLoadDataRecurringOrders = false;
          })
          .catch((error) => {
            console.log(error);
            this.isReLoadDataRecurringOrders = false
            this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "danger",
              icon: "AlertTriangleIcon",
              text: error.response.data.message,
              variant: "danger",
            },
          },
          {
            position: "top-center",
          }
        );
          })
        }
      else
      {
        this.reLoadDataRecurringOrdersAction()
      }
    },
    applyFilterPackagesCommandesAction(val) {
        const payloadFiltreStatus = {
        filter: val
      }
            
      console.log(val)
      if (this.filtreStatus == null) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Warning",
              icon: "CheckIcon",
              text: "Veuillez sélectionner au moins un champ du filtre.",
              variant: "warning",
            },
          },
          {
            position: "top-center",
          }
        );
      } else {

        if(val != ""){
          this.isReLoadDataRecurringOrders = true;
        console.log(val)
          this.filterPackagesCommandesAction(payloadFiltreStatus)
          .then((response) => {
            console.log(response)
           
            if(response.data.length !== 0){
               this.clientPackage = response.data
               this.total = this.clientPackage.length
            }
            else{
                this.clientPackage = []
            }
            this.isReLoadDataRecurringOrders = false;
          })
          .catch((error) => {
            console.log(error);
            this.isReLoadDataRecurringOrders = false;
            this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "danger",
              icon: "AlertTriangleIcon",
              text: error.response.data.message,
              variant: "danger",
            },
          },
          {
            position: "top-center",
          }
        );
          });
        }
      
        else
        {
          this.reLoadDataRecurringOrdersAction()
        }
        }
    },


    applyFilterPackagesAction() {
      const allIsNUl = Object.values(this.payloadFilter).every((el) => el === "");
      if (allIsNUl) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Warning",
              icon: "CheckIcon",
              text: "Veuillez sélectionner au moins un champ du filtre.",
              variant: "warning",
            },
          },
          {
            position: "top-center",
          }
        );
      } else {
        this.isReLoadFilterDataRecurringOrders = true;
        // this.$store.commit('SET_IS_DIRECT_ORDERS_FILTER_LOADING', true)
        // this.rows = []
        this.payloadFilter.type = "RM"
        console.log(this.payloadFilter.user_id)
        // this.payloadFilter.user_id = this.customerSelect
        this.filterPackagesAction(this.payloadFilter)
          .then((response) => {
            console.log(response)
           
            if(response.data.length !== 0){
               this.clientPackage = response.data
               this.total = this.clientPackage.length
            }
            else{
                this.clientPackage = []
            }
            this.isReLoadFilterDataRecurringOrders = false;
          })
          .catch((error) => {
            this.isReLoadFilterDataRecurringOrders = false;
            this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "danger",
              icon: "AlertTriangleError",
              text: error.response.data.message,
              variant: "danger",
            },
          },
          {
            position: "top-center",
          }
        );
          });
      }
    },


    openContract(data) {
      this.$router.push({
        path: '/preview/pdf',
        query: {
          makeActionOnPage: false, actionType: null, isSigned: true, pdf: data.contract_file_url,
        },
      })
    },

    sendContractByWhatsApp(data) {
      window.open(`https://wa.me/${data.user.phone_number}?text=Nos salutions! 
Merci de cliquer sur le lien suivant afin de consulter le contract de prestation de votre commande ${data.recurring_service.name}. \n\n *${data.contract_file_url}*`)
    },

    sendPlecementFeesPaymentLink(data) {
      window.open(`https://wa.me/${data.user.phone_number}?text=Nos salutations!
                      Merci de cliquer sur le lien suivant afin de payer les frais de placement pour
                      votre demande de *${data.recurring_service.name}*.
                      \n\n https://ylomi.net/direct/${data.id}/placement`)
    },

    resolveOrderStatusVariant: (status, placementCostPaid, searchEmployee) => {
      if (status === -1) return 'danger'
      if (status === 0 && placementCostPaid === false && searchEmployee === true) {
        return 'danger'
      }
      if (status === 0) return 'warning'
      if (status === 1) return 'info'
      if (status === 2) return 'warning'
      if (status === 3 || status === 4) return 'success'
      return 'primary'
    },

    customTab(array) {
      const tab = []
      for (let index = 0; index < array.length; index++) {
        const element = array[index]
        if (
          element.status === 0
          && element.placement_cost_paid === false
          && element.search_employee === true
        ) {
          element.setStatus = 'Non Payé'
          tab.push(element)
          continue
        }

        if (element.status === 0 && element.feedback === "") {
          element.setStatus = 'En attente de feedback'
          tab.push(element)
          continue
        }

        if (
          element.status === 0
          && element.feedback !== ""
          && element.recommended_employees_count === 0
        ) {
          element.setStatus = 'En attente de recommandation'
          tab.push(element)
          continue
        }

        if (
          element.status === 0
          && element.feedback != ""
          && element.proposed_employees_count === 0
        ) {
          element.setStatus = 'En attente de proposition'
          tab.push(element)
          continue
        }
        if (element.status === -1) {
          element.setStatus = 'Résilié'
          tab.push(element)
          continue
        }
        if (element.status === 1) {
          element.setStatus = 'Employé proposé'
          tab.push(element)
          continue
        }
        if (element.status === 2) {
          element.setStatus = 'Contract émis'
          tab.push(element)
          continue
        }
        if (element.status === 3) {
          element.setStatus = 'Contract approuvé'
          tab.push(element)
          continue
        }
        if (element.status === 4) {
          element.setStatus = 'Actif'
          tab.push(element)
          continue
        }
        if (element.status === 5) {
          element.setStatus = 'Terminée'
          tab.push(element)
          continue
        }
      }
      return tab
    },
  },
}
</script>

<style>
i.icon-code {
  display: none !important;
}

.vgt-select {
  background: transparent !important;
  border-color: #404656 !important;
}

.itemActions :hover {
  background: transparent !important;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 200px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.v-list .v-list-item--active {
  background-color: green !important;
}

.v-list .v-list-item--active .v-list-item__title {
  color: #ffd54f !important;
}
.red-card {
  background-color: rgba(255, 0, 0, 0.527)!important;
  color: white !important;
}
</style>
